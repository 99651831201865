export const CarouselSlides = [
  {
    sliderImg: "/images/carousels/carousel1.webp",
  },
  {
    sliderImg: "/images/carousels/carousel2.webp",
  },
  {
    sliderImg: "/images/carousels/carousel3.webp",
  },
  {
    sliderImg: "/images/carousels/carousel4.webp",
  },
  {
    sliderImg: "/images/carousels/carousel5.webp",
  },
];

export const ClientSlides = [
  {
    sliderImg: "/images/client-images/c1.webp",
  },
  {
    sliderImg: "/images/client-images/c2.webp",
  },
  {
    sliderImg: "/images/client-images/c3.webp",
  },
  {
    sliderImg: "/images/client-images/c4.webp",
  },
  {
    sliderImg: "/images/client-images/c5.webp",
  },
  {
    sliderImg: "/images/client-images/c6.webp",
  },
  {
    sliderImg: "/images/client-images/c7.webp",
  },
  {
    sliderImg: "/images/client-images/c8.webp",
  },
  {
    sliderImg: "/images/client-images/c9.webp",
  },
  {
    sliderImg: "/images/client-images/c10.webp",
  },
];

export const Tutors = [
  {
    _id: 1,
    fullName: "Himanshu Verma",
    tutorImage: "/images/tutors/tutor1.svg",
    review:
      "The X2 infotech is  business  to stay as close as possible to your customers understand their behavior, their preferences, their purchasing patterns, etc. At x2 Infotech I've got everything I expected from them",
    rating: 5,
  },
  {
    _id: 2,
    fullName: "Yashika bandil",
    tutorImage: "/images/tutors/tutor2.svg",
    review:
      "If people believe they share values with a company, they will stay loyal to the brand That's why I'm with x 2 infotech today",
    rating: 5,
  },
  {
    _id: 3,
    fullName: "Arpit Agarwal",
    tutorImage: "/images/tutors/tutor3.svg",
    review:
      "Satisfaction is a rating. Loyalty is a brand. This line fits perfectly on X2 Infotech ",
    rating: 5,
  },
  {
    _id: 4,
    fullName: "Deepak Pal",
    tutorImage: "/images/tutors/tutor4.svg",
    review:
      "The management of the company was very good, I have got support from here all the time and service here at the right time, I have found here more than expected, the best thing about X2 Infotech is its nature budget friendly, my lowest price here work done here",
    rating: 4.9,
  },
  {
    _id: 5,
    fullName: "Shikha Badariya",
    tutorImage: "/images/tutors/tutor5.svg",
    review:
      "The working style of X2 Infotech is very creative and unique",
    rating: 5,
  },
  {
    _id: 6,
    fullName: "Sachin Jain",
    tutorImage: "/images/tutors/tutor6.svg",
    review:
      "Success is the sum of small efforts, repeated day in and day out.",
    rating: 4.9,
  },
];

export const clientReviews = [
  {
    _id: 1,
    fullName: "Ashish Vishwakarma",
    tutorImage: "/images/tutors/tutor1.svg",
    review:
      "The X2 infotech is  business  to stay as close as possible to your customers understand their behavior, their preferences, their purchasing patterns, etc. At x2 Infotech I've got everything I expected from them",
    rating: 5,
  },
  {
    _id: 2,
    fullName: "Nisha Gupta",
    tutorImage: "/images/tutors/tutor2.svg",
    review:
      "If people believe they share values with a company, they will stay loyal to the brand That's why I'm with x 2 infotech today",
    rating: 5,
  },
  {
    _id: 3,
    fullName: "David",
    tutorImage: "/images/tutors/tutor3.svg",
    review:
      "Satisfaction is a rating. Loyalty is a brand. This line fits perfectly on X2 Infotech ",
    rating: 5,
  },
  {
    _id: 4,
    fullName: "Amit Sharma",
    tutorImage: "/images/tutors/tutor4.svg",
    review:
      "The management of the company was very good, I have got support from here all the time and service here at the right time, I have found here more than expected, the best thing about X2 Infotech is its nature budget friendly, my lowest price here work done here",
    rating: 4.9,
  },
  {
    _id: 5,
    fullName: "Moras",
    tutorImage: "/images/tutors/tutor5.svg",
    review:
      "The working style of X2 Infotech is very creative and unique",
    rating: 5,
  },
  {
    _id: 6,
    fullName: "Abhishek Rathor",
    tutorImage: "/images/tutors/tutor6.svg",
    review:
      "Success is the sum of small efforts, repeated day in and day out.",
    rating: 4.9,
  },
];

export const portfolios = [
  {
    _id: 1,
    projectName: "C.K. Vishwakarma",
    portfolioImg: "/images/portfolio/p1.webp",
  },
  {
    _id: 2,
    projectName: "Aman Tomar",
    portfolioImg: "/images/portfolio/p2.webp",
  },
  {
    _id: 3,
    projectName: "Sachin Jain",
    portfolioImg: "/images/portfolio/p3.webp",
  },
  {
    _id: 4,
    projectName: "Popat Lal",
    portfolioImg: "/images/portfolio/p4.webp",
  },
  {
    _id: 5,
    projectName: "Jetha Lal",
    portfolioImg: "/images/portfolio/p5.webp",
  },
  {
    _id: 6,
    projectName: "Babita Ayer",
    portfolioImg: "/images/portfolio/p6.webp",
  },
  {
    _id: 7,
    projectName: "Babita Ayer",
    portfolioImg: "/images/portfolio/p7.webp",
  },
  {
    _id: 8,
    projectName: "Babita Ayer",
    portfolioImg: "/images/portfolio/p8.webp",
  },
  {
    _id: 9,
    projectName: "Babita Ayer",
    portfolioImg: "/images/portfolio/p9.webp",
  },
];
